<template>
  <svg :rate="rate" :dates="dates" class="timest">
    <!--DATE表示-->
    <text
      v-for="(n, i) in this.dates + 1"
      v-bind:key="i + Math.random()"
      :x="getXDate(n - 1)"
      y="15"
    >
      {{ getDate(n - 1) }}
    </text>
    <!--Time表示-->
    <text
      v-for="(n, i) in 12 * this.dates + 1"
      v-bind:key="i + Math.random()"
      :x="getXTime(n - 1)"
      y="45"
      fill="black"
    >
      {{ getTime(n - 1) }}
    </text>
  </svg>
</template>
<script>
export default {
  props: {
    /**
     * rate
     */
    rate: {
      type: Number,
      default: 1,
    },
    /**
     * date
     */
    dates: {
      type: Number,
      default: 1,
    },
    /**
     * hyojiStart
     */
    hyojiStart: {
      type: String,
      default: '',
    },
    /**
     * hyojiEnd
     */
    hyojiEnd: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     *  TimeX座標
     */
    getXTime(value) {
      //console.log('★★value:' + value);
      var x = value * 120 * this.rate + 21
      return x
    },
    /**
     *  DateX座標
     */
    getXDate(value) {
      var x = value * 1440 * this.rate
      //console.log('★x★' + x);
      return x
    },
    /**
     *  Time
     */
    getTime(n) {
      //0-24
      var d = new Date()
      d.setUTCHours(n * 2)
      var hour = d.getUTCHours()
      var time = '00:00'
      if (hour < 10) {
        time = '0' + hour + ':00'
      } else {
        time = hour + ':00'
      }
      return time
    },
    /**
     *  Date
     */
    getDate(n) {
      let start = this.convertDate(this.hyojiStart)
      // console.log('start:::' + start)
      // console.log('n:::' + n)
      start.setDate(start.getDate() + n)
      var sub = this.getFormatDate(start)
      // console.log('sub:::' + sub)
      return sub
    },
    getOnlyDate(value) {
      let year = value.getFullYear()
      let monthIndex = value.getMonth()
      let date = value.getDate()
      var onlyDate = new Date(year, monthIndex, date, 'yyyy/MM/dd')
      return onlyDate
    },
    convertDate(value) {
      var year = value.substring(0, 4)
      var monthIndex = Number(value.substring(4, 6)) - 1
      var day = value.substring(6, 8)

      value = new Date(year, monthIndex, day)
      return value
    },
    /**
     *  yyyy/MM/dd Format
     */
    getFormatDate(date) {
      var year = date.getFullYear() // yyyy
      var month = date.getMonth() + 1 // monthIndex + 1
      // console.log('date:', date)
      // console.log('month:', month)
      month = month >= 10 ? month : '0' + month //month 二桁に貯蔵
      var day = date.getDate() //d
      day = day >= 10 ? day : '0' + day //day 二桁に貯蔵

      return year + '/' + month + '/' + day //yyyy/mm/dd
    },
  },
}
</script>

<style lang="scss" scoped>
.timest {
  text-align: left;
  width: 250%;
  display: inline-block;
  height: 60px;
}
</style>
